// eslint-disable-next-line object-curly-newline
import React, { useState, useRef, useCallback, useEffect } from 'react';
import BTE from 'lib/BTE';
import { StickyPIP } from 'components/StickyPIP';
/* eslint-disable import/no-unresolved */
import { useIsVideoPaused } from '@nbcnews/omega-player/context/state/isPaused';
import { useVideoVolume } from '@nbcnews/omega-player/context/state/volume';
import { useVideoController } from '@nbcnews/omega-player/context/videoController';
/* eslint-enable import/no-unresolved */
import { VIDEO_RESERVE_PIP_GLOBAL_EVENT, VIDEO_PLAYING_EVENT_ATTENUATION } from 'lib/videoUtils';

/**
 * Sticky Picture-in-Picture (PiP) control component.
 *
 * @param {object} props - The properties for the StickyPipControl component.
 * @param {React.ReactNode} props.children - The child elements to render inside the component.
 * @param {string} [props.className] - Additional CSS class for the component.
 * @param {string} [props.placeholderClassName] - CSS class for the placeholder element.
 * @param {boolean} [props.shouldStickOnlyOnFirstView=false] - Flag to stick only on the first view.
 * @param {boolean} [props.disableStickyOnMute=false] - Flag to disable sticky mode when muted.
 * @param {boolean} [props.disableStickyOnPause=true] - Flag to disable sticky mode when paused.
 * @param {'top' | 'bottom'} [props.pipAlignDesktop='bottom'] - Alignment of PiP on desktop.
 * @param {'top' | 'bottom'} [props.pipAlignMobile='bottom'] - Alignment of PiP on mobile.
 * @param {Function} [props.onStuckChange=() => {}] - Callback function when the stuck state changes.
 * @param {boolean} [props.noStuckChangeInFullscreen=false] - Flag to prevent stuck state change in fullscreen.
 */
export function StickyPipControl({
  children,
  className,
  placeholderClassName,
  shouldStickOnlyOnFirstView = false,
  disableStickyOnMute = false,
  disableStickyOnPause = true,
  pipAlignDesktop = 'bottom',
  pipAlignMobile = 'bottom',
  onStuckChange = () => {},
  noStuckChangeInFullscreen = false,
}) {
  const [isStickDisabled, setIsStickDisabled] = useState(false);
  const isPaused = useIsVideoPaused();
  const { play, pause } = useVideoController();
  const { muted: isMuted } = useVideoVolume();
  const frameRef = useRef({});

  useEffect(() => {
    const onReservePip = ({ ref }) => setIsStickDisabled(ref !== frameRef.current);

    const args = [VIDEO_RESERVE_PIP_GLOBAL_EVENT, onReservePip, VIDEO_PLAYING_EVENT_ATTENUATION];

    BTE.on(...args);

    return () => BTE.remove(...args);
  }, []);

  const handleOnStuckChange = useCallback(
    (isStuck) => {
      onStuckChange(isStuck);
      if (isStuck) {
        BTE.trigger(
          VIDEO_RESERVE_PIP_GLOBAL_EVENT,
          { ref: frameRef.current },
          VIDEO_PLAYING_EVENT_ATTENUATION,
        );
      }
    },
    [onStuckChange],
  );

  const handleOnStickyClose = useCallback(
    (isClosed) => {
      if (isClosed && !isPaused) {
        pause();
      }
    },
    [isPaused, pause],
  );

  const shouldStickOnViewportExit = useCallback(() => {
    // only look at the value of `isMuted` if `disableStickyOnMute` is true,
    // but then we want `enabled` only if NOT `isMuted`
    const isStickyEnabledBasedOnMuteState = !(disableStickyOnMute && isMuted);

    // only look at the value of `playing` if `disableStickyOnPause` is true
    // otherwise, always enabled if `disableStickyOnPause` is false
    const isStickyEnabledBasedOnPlayingState = disableStickyOnPause ? !isPaused : true;

    return isStickyEnabledBasedOnMuteState && isStickyEnabledBasedOnPlayingState;
  }, [disableStickyOnMute, disableStickyOnPause, isMuted, isPaused]);

  const handleOnInViewChange = useCallback(
    (isInView) => {
      if (!isPaused) {
        setIsStickDisabled(false);
      } else if (isInView && isMuted) {
        setIsStickDisabled(false);

        if (!isPaused) play();
      }
    },
    [isMuted, isPaused, play],
  );

  return (
    <StickyPIP
      className={className}
      getShouldStickOnViewportExit={shouldStickOnViewportExit}
      onClose={handleOnStickyClose}
      onStuckChange={handleOnStuckChange}
      enabled={!isStickDisabled}
      onInViewChange={handleOnInViewChange}
      placeholderClassName={placeholderClassName}
      shouldStickOnlyOnFirstView={shouldStickOnlyOnFirstView}
      pipAlignDesktop={pipAlignDesktop}
      pipAlignMobile={pipAlignMobile}
      noStuckChangeInFullscreen={noStuckChangeInFullscreen}
    >
      {children}
    </StickyPIP>
  );
}
