import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFeatureFlagContext, RouteContext } from 'lib/ContextTypes';
import { useIsClient } from 'lib/Hooks/useIsClient';
import { ErrorSlate } from 'components/OmegaVideoPlayer/ErrorSlate';
import {
  NEWS_NOW_DEFAULT_SLATE,
  /* eslint-disable-next-line no-unused-vars */
  STREAM_KEYS,
  STREAM_TO_SLATE_MAP,
} from 'lib/liveVideo';
import { tryParse, isValidAbsoluteUrl } from 'lib/urlUtils';
import styles from './styles.module.scss';

/**
 * OmegaSlate: Shows a slate when OmegaPlayer is loading or has an error.
 *
 * @typedef {object} OmegaSlateProps
 * @param {OmegaSlateProps} props
 * @property {boolean} coreVideoReady Flag to indicate if OmegaPlayer is ready.
 * @property {boolean} hasError Flag to indicate if an error occurred in OmegaPlayer.
 * @property {object} errorCause String to indentify error code that occurred in OmegaPlayer.
 * @property {STREAM_KEYS} stream STREAM_KEY value to determine correct slate image.
 * @property {string} playmakerArt The playmaker art chosen for the video slate.
 * @returns {JSX.Element|null} A JSX element representing the slate.
 */

/**
 *
 * @param {object} root0
 * @param {boolean} root0.coreVideoReady
 * @param {boolean} root0.hasError
 * @param {string} root0.stream
 * @param {{ message: string }} root0.errorCause
 * @param {string} root0.playmakerArt
 */
const OmegaSlate = ({
  coreVideoReady,
  hasError,
  stream,
  errorCause,
  playmakerArt = null,
}) => {
  const isClient = useIsClient();
  const { 'googlebot-slate': enableGooglebotSlate, 'use-election-slate': enableElectionSlate } = useFeatureFlagContext();
  const { domain, path } = useContext(RouteContext);

  if (coreVideoReady && !hasError) return null;


  /** @type {keyof typeof STREAM_TO_SLATE_MAP} */
  const slateKey = stream === STREAM_KEYS.NEWS_NOW
    && enableElectionSlate
    && tryParse(`http://${domain}${path}`)?.searchParams?.get('election-page') === 'true'
    ? 'election'
    : stream;

  const playmakerArtOverride = isValidAbsoluteUrl(playmakerArt) ? playmakerArt : null;

  const loadingSlate = {
    img: playmakerArtOverride ?? STREAM_TO_SLATE_MAP[slateKey] ?? NEWS_NOW_DEFAULT_SLATE,
    alt: 'Loading video. Please wait.',
  };

  const connectionErrorHeading = 'Something went wrong!';
  const connectionErrorDetails = 'Please check your internet connection and try again later.';

  /*
  Omega uses device detection to determine which stream format & drm format to use. Chrome on desktop
  only supports DASH, but iOS supports HLS, causing a mismatch. This causes Google bots like Lighthouse
  and PageSpeed, which disguises as a Chrome iOS user, to experience live Omega streams as errors.
  */
  const chromeIOSUserAgent = ['Chrome', 'Mobile', 'iPhone', 'OS'];

  /*
    ShakaError code 4026 is produced when Omega registers a Chrome iOS user agent
    https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html
    The ideal way to choose the stream format would be to use an approach that checks
    the media capabilities rather than the browser/user agent, but IIRC when we last checked that approach wasn’t viable
   */

  const isBot = enableGooglebotSlate && isClient
    && chromeIOSUserAgent.some((agent) => navigator.userAgent.includes(agent))
    && errorCause?.message?.includes('4026');

  const errorSlate = <ErrorSlate slateType="connection-error-slate" heading={connectionErrorHeading} details={connectionErrorDetails} />;
  const slate = (hasError && !isBot) ? errorSlate : <img src={loadingSlate.img} alt={loadingSlate.alt} className="omega-loading-slate" />;

  const slateStyles = classNames(
    styles.omegaVideoSlate,
    styles.omegaVideoSlateContainer,
  );

  return (
    <div className={slateStyles}>
      {slate}
    </div>
  );
};

OmegaSlate.propTypes = {
  coreVideoReady: PropTypes.bool.isRequired,
  errorCause: PropTypes.objectOf(PropTypes.string).isRequired,
  hasError: PropTypes.bool.isRequired,
  stream: PropTypes.string.isRequired,
};

export { OmegaSlate };
