import { useEffect, useState } from 'react';

/**
 * Custom hook to determine if the component is mounted on the client side.
 * @returns {boolean} - Returns true if the component is mounted on the client side.
 */
export function useIsClient() {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  return isClient;
}
