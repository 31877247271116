import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import Breakpoints from 'lib/Breakpoints';
import getNavbarHeight from 'lib/getNavbarHeight';

import { Placeholder } from 'components/Picture/Placeholder';
import { VideoPlayerContext } from 'lib/ContextTypes/videoPlayer';

import './styles.themed.scss';

const block = 'sticky-pip';

/**
 * Sticky Picture-in-Picture (PiP) component.
 *
 * @param {object} props - The properties for the StickyPIP component.
 * @param {React.ReactNode} props.children - The child elements to render inside the component.
 * @param {string} [props.className] - Additional CSS class for the component.
 * @param {Function} [props.getShouldStickOnViewportExit] - Function to determine if the component should stick on viewport exit.
 * @param {string} [props.placeholderClassName] - CSS class for the placeholder element.
 * @param {Function} [props.onClose] - Callback function when the component is closed.
 * @param {Function} [props.onInViewChange] - Callback function when the in-view state changes.
 * @param {Function} [props.onStuckChange] - Callback function when the stuck state changes.
 * @param {boolean} [props.shouldStickOnlyOnFirstView=true] - Flag to stick only on the first view.
 * @param {boolean} [props.enabled=true] - Flag to enable or disable the component.
 * @param {'top' | 'bottom'} [props.pipAlignDesktop='bottom'] - Alignment of PiP on desktop.
 * @param {'top' | 'bottom'} [props.pipAlignMobile='bottom'] - Alignment of PiP on mobile.
 * @param {boolean} [props.noStuckChangeInFullscreen=false] - Flag to prevent stuck state change in fullscreen.
 * @param {object} [context] - The context object.
 */
function StickyPIP({
  children,
  className,
  getShouldStickOnViewportExit,
  placeholderClassName,
  onClose,
  onInViewChange,
  onStuckChange,
  shouldStickOnlyOnFirstView = true,
  enabled = true,
  pipAlignDesktop = 'bottom',
  pipAlignMobile = 'bottom',
  noStuckChangeInFullscreen = false,
}, context = {}) {
  const { t } = useTranslation();

  const [isClosed, setIsClosed] = React.useState(false);
  const [isInView, setIsInView] = React.useState(true);
  const [shouldStickOnViewportExit, setShouldStickOnViewportExit] = React.useState(true);
  const [hasBeenInView, setHasBeenInView] = React.useState(false);
  const videoPlayerContext = React.useContext(VideoPlayerContext);
  const fullscreenActive = !!document.fullscreenElement;

  const handleObserverCallback = React.useCallback((inView) => {
    if (noStuckChangeInFullscreen && fullscreenActive) {
      return;
    }
    setIsInView(inView);
    if (inView) {
      // setting the same value a 2nd time doesn't trigger a rerender
      setHasBeenInView(true);
      setIsClosed(false);
    }
    if (!inView && typeof getShouldStickOnViewportExit === 'function') {
      setShouldStickOnViewportExit(getShouldStickOnViewportExit());
    }
    if (typeof onInViewChange === 'function') {
      onInViewChange(inView);
    }
  }, [onInViewChange, getShouldStickOnViewportExit, noStuckChangeInFullscreen, fullscreenActive]);

  // eslint-disable-next-line react/destructuring-assignment
  const setStickyVideoFlag = context?.setStickyVideoFlag
  || videoPlayerContext?.setStickyVideoFlag;

  const shouldStickBasedOnHasBeenInView = shouldStickOnlyOnFirstView
    ? hasBeenInView
    : true; // stick regardless if `shouldStickOnlyOnFirstView` is false

  const isStuck = (
    enabled
    && shouldStickBasedOnHasBeenInView
    && !isInView
    && !isClosed
    && shouldStickOnViewportExit
    && !(noStuckChangeInFullscreen && fullscreenActive)
  );

  React.useEffect(() => {
    if (noStuckChangeInFullscreen && fullscreenActive) {
      return;
    }
    if (typeof setStickyVideoFlag === 'function') {
      setStickyVideoFlag(isStuck);
    }
  }, [setStickyVideoFlag, isStuck, noStuckChangeInFullscreen, fullscreenActive]);

  React.useEffect(() => {
    if (noStuckChangeInFullscreen && fullscreenActive) {
      return;
    }
    if (typeof onClose === 'function') {
      onClose(isClosed);
    }
  }, [onClose, isClosed, noStuckChangeInFullscreen, fullscreenActive]);

  React.useEffect(() => {
    if (noStuckChangeInFullscreen && fullscreenActive) {
      return;
    }
    if (typeof onStuckChange === 'function') {
      onStuckChange(isStuck);
    }
  }, [onStuckChange, isStuck, noStuckChangeInFullscreen, fullscreenActive]);

  const navbarHeight = getNavbarHeight(Breakpoints);

  return (
    <InView
      className={className}
      onChange={handleObserverCallback}
      rootMargin={`${-navbarHeight}px 0px 0px 0px`}
      threshold={0.3}
    >
      <Placeholder
        responsiveFlavors={{ s: AIMS_FLAVORS.FOCAL_860x484 }}
        className={placeholderClassName}
      />
      <div
        className={classNames(block, {
          [`${block}--stuck`]: isStuck,
          [`${block}--desktopTop`]: pipAlignDesktop === 'top',
          [`${block}--desktopBottom`]: pipAlignDesktop === 'bottom',
          [`${block}--mobileTop`]: pipAlignMobile === 'top',
          [`${block}--mobileBottom`]: pipAlignMobile === 'bottom',
        })}
        data-testid="sticky-pip__sticky-container"
      >
        {children}
        <div className={`${block}__close`}>
          <button
            type="button"
            className={`${block}__close-button icon icon-close`}
            onClick={() => setIsClosed(true)}
          >
            <span className={`${block}__close-label`}>
              {t('Close')}
            </span>
          </button>
        </div>
      </div>
    </InView>
  );
}

StickyPIP.contextTypes = {
  setStickyVideoFlag: PropTypes.func,
};

export { StickyPIP };
